import {convertAniBinaryToCSS} from 'ani-cursor';
import pinkFlame from './static/cursors/pink_flame.ani';
import heartbeat from './static/cursors/heartbeat.ani';
import eye from './static/cursors/eye.ani';
import yoshi from './static/cursors/yoshi.ani';
import $ from 'jquery';

async function applyCursor(selector, aniUrl) {
    const response = await fetch(aniUrl);
    const data = new Uint8Array(await response.arrayBuffer());

    const style = document.createElement('style');
    style.innerText = convertAniBinaryToCSS(selector, data);

    document.head.appendChild(style);
}


$(document).ready(function() {

    // Pink Flame Pointer: Default
    applyCursor("*", pinkFlame);

    // Heartbeat: .gallery-image
    applyCursor(".gallery-image", heartbeat);

    // Blinking Eye: .nav-link
    applyCursor(".nav-link", eye);
    applyCursor(".shows-list", eye);
    applyCursor(".shows-list *", eye);

    // YOSHI: .discography and all it's elements
    applyCursor(".discography *", yoshi)
    applyCursor(".discography", yoshi);
});
