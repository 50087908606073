import './static/css/NavBar.css'


function PressKit() {
    return (
        <div className="continer press-kit">

        </div>
    )
}

export default PressKit