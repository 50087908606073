import React from "react";
import '../static/css/Discography.css';

function Discography({ releases }) {
    return (
        <div className="discography">
            {releases.map((release, index) => (
                <div className="release" key={index}>
                    <div className="release-image">
                        <a href={release.Spotify}>
                        <span className="hidden-descriptor">
                                    {release.title} Cover Art</span>
                            <img className="release-image" src={release.imageSource} alt={release.title + "Cover Art"} />
                        </a>
                    </div>
                    <div className="release-info">
                        <h3 className="release-title">{release.title}</h3>
                        <h4 className="release-links">
                            {/* Spotify */}
                            {release.Spotify && (
                                <a href={release.Spotify} className="neon-button social-link">
                                <span className="hidden-descriptor">{release.title} Spotify</span>
                                    <i className="fa-brands fa-spotify" alt="Spotify"></i>
                                </a>
                            )}

                            {/* Apple Music */}
                            {release.AppleMusic && (
                                <a href={release.AppleMusic} className="neon-button social-link">
                                    <span className="hidden-descriptor">{release.title} Apple Music</span>
                                    <i className="fa-brands fa-itunes-note" alt="Apple Music"></i>
                                </a>
                            )}

                            {/* Bandcamp */}
                            {release.Bandcamp && (
                                <a href={release.Bandcamp} className="neon-button social-link">
                                    <span className="hidden-descriptor">
                                    {release.title} Bandcamp</span>
                                    <i className="fa-brands fa-bandcamp" alt="Bandcamp"></i>
                                </a>
                            )}
                        </h4>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Discography;
