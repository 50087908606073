
import React from "react";

function ShowsView ({ shows }) {
    return (
        <div id="Shows" className="shows-list">
            <h1>SHOWS</h1>
            <ul>
                { shows.map((show,index) => (
                    <li className="show">
                    <a href={show.href} className="show-link">
                        <div className="show-details">{show.showDetails}</div>
                    </a>
                </li>
                ))}
            </ul>
        </div>
    );
}

export default ShowsView;