import './static/css/Home.css';
import './static/css/NavBar.css';
import './static/css/shows.css'
import NavLinks from './views/NavLinks'
import Discography from './views/Discography'
import React from 'react';
import ImageGallery from "./views/ImageGallery";
import ShowsView from "./views/shows";
import EPK from './views/epk'
import shows from "./data/shows.js";
import PrettyNameImage from "./static/images/pretty_name.jpg"
import JustDyingImage from "./static/images/just-dying.jpeg"

const links = [
    { text: 'HOME', href: '#Home' },
    { text: 'SHOWS', href: '#Shows' },
    { text: 'PRESS KIT', href: '#EPK' },
    { text: 'MERCH', href: 'http://shop.bazravish.com' },
];

const discographyReleases = [
    { title: 'Pretty Name', date: '12th of December 2023', imageSource: PrettyNameImage, 
    Spotify: 'https://open.spotify.com/album/4fzkC2L3soOcQmfBigCF8b?si=0lZOuvQMQyqmRdXA0vV7Ag', 
    AppleMusic: 'https://music.apple.com/au/album/pretty-name-single/1719349646',
    },
     
    { title: 'Just Dying', date: '24th of August 2022', imageSource: JustDyingImage, 
    Spotify: 'https://open.spotify.com/track/4y9dwGFGMnJcKrgk9Xvg2E?si=8eeb932cd54042f1', 
    AppleMusic: 'https://music.apple.com/au/album/just-dying-ep/1639371561'},
]

function Home() {
    return (
        <div id="Home" className="Home">
            <NavLinks links={links}></NavLinks>
            <Discography releases={ discographyReleases }/>
            <ShowsView shows={ shows }></ShowsView>
            <EPK></EPK>
            <ImageGallery src="posters"></ImageGallery>
        </div>
);
}

export default Home;
