import React, { useEffect, useState } from 'react';
import '../static/css/ImageGallery.css'
import {proxy_url} from "../config";

function ImageGallery({ src }) {
    const [galleryImages, setGalleryImages] = useState([]);

    const fetchImageGallery = async () => {
        try {

            const response = await fetch(proxy_url + 'image-gallery/' + src);
            const data = await response.json();
            const imageFiles = data['image_files'];
            setGalleryImages(imageFiles);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchImageGallery();
    }, []);


    const imageGallery = () => {
        const views = [];
        for (let index = 0; index < galleryImages.length; index++) {
            const image = galleryImages[index];
            const imagePath = proxy_url + `static/images/${src.toString()}/${image.toString()}`;
            const id = "gallery-image-" + index;
            const image_view = (
                <div id={id} className="col-md-3 square-image-container">
                   <img src={imagePath} alt="gallery-image-${index}" className="img-fluid gallery-image square-image"/>
                </div>
            );
            views.push(image_view);
        }


        return (
            <div id="Gallery" className="gallery container">
                <h1>GALLERY</h1>
                <div className="row">
                    {views}
                </div>
            </div>
        )
    }

    return (imageGallery())
}


export default ImageGallery;
