import bandPhoto from '../static/images/band_photo2.jpg'
import livePhoto from '../static/images/EP-lauch-live.png'
import '../static/css/epk.css'
import {proxy_url} from "../config";
function EPK () {
    return (
        <div id="EPK" className="press-kit">
            <div className="bbox">
                <div className="bio">
                    <h1>E.P.K</h1>
                    Baz Ravish were born and raised in the rock and blues loving towns of the Northern 
                    Rivers of NSW, Australia. 
                    They are now a growing pillar of garage rock in Melbourne, bringing the 
                    psychedelic blues influences of their home region to the punk scene. 
                    Their live show is a melting pot of genres from western, punk, to prog rock, 
                    always delivered with a rock and roll attitude.
                    <br></br><br></br>
                    The band is comprised of Tom Gosling (guitar, vocals), Hudson Taig (guitar, 
                    keys, and vocals), Angus Moller (bass), Otis Namrell (guitar) and Carlo Fisher 
                    (drums). Their debut EP “Just Dying” was released in 2022 and their debut album 
                    is set to be released mid 2024.

                </div>
            </div>

            <div className="bio-photos">
                <img src={proxy_url + "static/images/posters/just-dying-launch.webp"} alt="Just Dying By Baz Ravish Cover Art"/>
                <img src={bandPhoto} alt="Baz Ravish Bio"/>
                <img src={livePhoto} alt="Just Dying EP Launch Live"/>
            </div>
            </div>
    );
}

export default EPK;