import React, { useEffect } from 'react';
import largeBanner from '../static/images/phone_booth_red_trans.png';

export default function NavLinks({ links }) {
    // Function to handle the scrolling
    function scrollToDiv(elementId) {
        document.getElementById(elementId).scrollIntoView({
            behavior: 'smooth' // Use smooth scrolling animation
        });
    }

    // Attach a click event listener to the links
    function handleClick(event) {
        var href = event.target.getAttribute('href'); // Get the href attribute of the clicked element
        if (href && href.indexOf('http') === 0) {
            return; // If href starts with "http", perform default behavior
        }
        
        event.preventDefault(); // Prevent default link behavior
        var target = href.substring(1); // Extract the target element id
        scrollToDiv(target); // Scroll to the target element
    }

    useEffect(() => {
        const links = document.querySelectorAll('a[href^="#"], a[href^="/"]');
        links.forEach(link => link.addEventListener('click', handleClick));
    
        // Clean up event listeners when the component unmounts
        return () => {
            links.forEach(link => link.removeEventListener('click', handleClick));
        };
    }, []); // Empty dependency array ensures this effect runs only once
    
    // Render the component
    return (
        <div className="navigation-wrapper">
            <div className="banner">
                <a href={"https://open.spotify.com/album/3iua1byZp37VEo5ypLa4qa?si=vMkrrQcPRhSuS1AK5htowQ&nd=1"}>
                    <img src={largeBanner} alt="Baz Ravish Logo" className="img-fluid large-banner"></img>
                </a>
            </div>
            <div id="navigation-menu" className="navigation-menu">
                <ul className="navigation-links-list">
                    {links.map((link, index) => (
                        <li key={index} className="nav-link">
                            <a href={link.href} className="nav-link">
                                {link.text}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
