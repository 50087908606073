const shows = [
    {
        "href": "",
        "showDetails": "30-05-2024, The Cactus Room, Headline Show"
    },
    {
        "href": "",
        "showDetails": "23-05-2024, Old Bar, Supporting Killing Leaders"
    }, 
    {
        "href": "https://www.instagram.com/p/C4W0CivyRYa/",
        "showDetails": "11-05-2024, The Cactus Room, GLAS FEST"
    }, 
    {
        "href": "",
        "showDetails": "05-05-2024, The Last Chance Rock and Roll Bar, Headline Show"
    },  
    {
        "href": "https://theworkersclub.oztix.com.au/outlet/event/553ec16c-ab2b-4c15-aed1-72d9a5bccec0",
        "showDetails": "05-04-2024, The Workers Club, Bigfoot Single Launch"
    },  
    {
        "href": "https://theleadbeaterhotel.oztix.com.au/outlet/event/aee516e9-63e4-4710-80a8-4edbeeeb5687",
        "showDetails": "22-02-2024, The Leadbeater Hotel, Your Anarchy Single Launch"
    },  
    {
        "href": "https://www.eventbrite.com.au/e/avalanche-live-at-cherry-bar-sat-feb-17th-tickets-802194333847?aff=oddtdtcreator",
        "showDetails": "17-02-2024, The Cherry Bar, Supporting Avalanche"
    },
    {
        "href": "https://www.instagram.com/p/C2gxpSqBi3s/",
        "showDetails": "26-01-2024, The Corner Hotel, Supporting The Angels"
    },    
    {
        "href": "",
        "showDetails": "20-01-2024, The Rainbow Hotel, Supporting The Otis Namrell Trio"
    }
    // {
    //     "href": "",
    //     "showDetails": "24-11-2023 Brunswick, Penny Black, Supporting Bikini Sunrise"
    // },
    // {
    //     "href": "",
    //     "showDetails": "01-11-2023 Brunswick, The Retreat"
    // }
    // {
    //     "href": "https://www.instagram.com/p/Cu78m6yhwB6/",
    //     "showDetails": "26-07-2023 Melbourne, The Last Chance Rock & Roll Bar"
    // },
    // {
    //     "href": "https://www.instagram.com/p/CuY5r7GBRuG/",
    //     "showDetails": "12-07-2023 Fitzroy, The Old Bar"
    // },
    // {
    //     "href": "https://www.instagram.com/p/Co56wUmShJc/",
    //     "showDetails": "08-07-2023 -Thornbury, Cactus Room"
    // },
    // {
    //     "href": "https://www.instagram.com/p/CkVFb2OBmZX/",
    //     "showDetails": "11-12-2022 Collingwood, The Gasometer"
    // },
    // {
    //     "href": "https://www.instagram.com/p/CkVFb2OBmZX/",
    //     "showDetails": "09-12-2022 Richmond,The Leadbeater"
    // },
    // {
    //     "href": "https://www.instagram.com/p/CkVFb2OBmZX/",
    //     "showDetails": "06-11-2022 Melbourne, The Last Chance Rock & Roll bar"
    // },
    // {
    //     "href": "",
    //     "showDetails": "07-10-2022 Fitzroy, The Evelyn"
    // }
];


export default shows;
