import React from 'react';
import './static/css/index.css';
import Home from './Home';
import PressKit from "./PressKit";
import './cursor.js';
import { createRoot } from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
} from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "*",
        element: (
            <Home/>
        ),
    },
]);

createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
);